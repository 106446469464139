import PropTypes from "prop-types";
import React from "react";
import { Box } from "rebass/styled-components";

const Section = props => {
  return (
    <Box width="100%" pt={[11,11,11]} pb={[11,11,11]} {...props}>
      {props.children}
    </Box>
  );
};

Section.propTypes = {
  children: PropTypes.any
};

export default Section;
