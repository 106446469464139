import PropTypes from "prop-types";
import React, { useState } from "react";
import { Box, Flex, Text, Button } from "rebass/styled-components";
import { withTheme } from "styled-components";
import Container from "./UI/Container";
import Icofont from "./UI/Icofont";
import Link from "./UI/Link";
import Fontello from "./UI/Fontello";
import { useStaticQuery, graphql } from "gatsby";
import CustomImage from "./UI/CustomImage";
import loadable from "@loadable/component";

const Sticky = loadable(() => import("react-stickynode"));
const Drawer = loadable(() => import("./Drawer"));

const NavbarRaw = ({ theme, menusSettings, footerSettings, logoAlt }) => {
  logoAlt = logoAlt || "Logo";
  const [stiked, setStiked] = useState(false);
  const [open, setOpen] = useState(false);
  const toggleHandler = () => {
    setOpen(!open);
  };
  var telLink = "tel:+687";

  if (!menusSettings.activeTopMenu) {
    return <></>;
  }
  return (
    <Sticky top={0} innerZ={5} onStateChange={status => setStiked(status.status > 0)}>
      <Box variant="navbar" className={stiked ? "sticky" : "sticky-off"}>
        <Box display={["none", "none", "none", "block"]}>
          <Container>
            <Flex justifyContent="flex-end">
              <Box
                className="contact-header"
                bg="gray"
                py={1}
                pr={2}
                pl={3}
                sx={{
                  position: "relative",
                  "&:after": {
                    content: "''",
                    display: "block",
                    width: "100vw",
                    height: "100%",
                    bg: "gray",
                    position: "absolute",
                    top: 0,
                    left: "100%"
                  }
                }}
              >
                <Flex alignItems="center" fontSize={1}>
                  <Text mr={2}>Contactez-nous au</Text>
                  <a href={telLink + footerSettings.phoneFooter.replace(/[- )(]/g, "")}>
                    <Flex alignItems="center" color="primary">
                      <Box mr={2} fontSize={3}>
                        <Fontello icon="phone" />
                      </Box>
                      <Text fontWeight="bold">{footerSettings.phoneFooter}</Text>
                    </Flex>
                  </a>
                  <Box ml={4}>
                    <a href={menusSettings.socials.linkedin} target="_blank">
                      <Box fontSize={6}>
                        <Fontello icon="linkedin-new" />
                      </Box>
                    </a>
                  </Box>
                  <Box ml={2}>
                    <a href={menusSettings.socials.facebook} target="_blank">
                      <Box fontSize={6}>
                        <Fontello icon="facebook-new" />
                      </Box>
                    </a>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Container>
        </Box>

        <Container>
          <Flex alignItems="center" pb={[3]} pt={[3, 3, 3, 1]}>
            <Box>
              <Link href="/">
                <CustomImage
                  width={["120px", "150px", "150px", "150px", "175px"]}
                  height="auto"
                  img={stiked ? menusSettings.logo : menusSettings.logo2}
                  alt={logoAlt}
                />
              </Link>
            </Box>
            <Box mx="auto" />
            <Box alignItems="center" display={["none", "none", "none", "flex"]} pt={1}>
              {menusSettings.mainmenu
                .filter(item => item.inNavBar)
                .map((item, i) => (
                  <Box ml={[0, 0, 0, 3, 8]} key={"menu-" + i} variant="menuItem">
                    <Link activeClassName="active" href={item.path} external={item.external}>
                      <Button variant="ninja">
                        <Text>{item.title}</Text>
                      </Button>
                    </Link>
                  </Box>
                ))}
            </Box>
            <Drawer
              closeButton={
                <Box textAlign="right" p={2}>
                  <Button variant="ninja" fontSize={[8, 8, 9]}>
                    <Fontello icon="close" />
                  </Button>
                </Box>
              }
              placement="right"
              drawerHandler={
                <Button
                  variant="ninja"
                  display={["block", "block", "block", "none"]}
                  fontSize={[8, 8, 9]}
                >
                  <Fontello icon="menu" />
                </Button>
              }
              open={open}
              toggleHandler={toggleHandler}
            >
              <Box width={["100vw", "400px", "400px"]} bg="background">
                {menusSettings.mainmenu
                  .filter(item => item.inSideBar)
                  .map((item, i) => (
                    <Box mx={3} my={3} variant="menuItemOffcanvas" key={i}>
                      <Link href={item.path} external={item.external} onClick={toggleHandler}>
                        <Button
                          variant="ninja"
                          p={2}
                          width="100%"
                          textAlign={["center", "center", "left"]}
                        >
                          <Text>{item.title}</Text>
                        </Button>
                      </Link>
                    </Box>
                  ))}
              </Box>

              <Box
                sx={{
                  position: "absolute",
                  top: 4,
                  left: 4
                }}
              >
                <a href={telLink + footerSettings.phoneFooter.replace(/[- )(]/g, "")}>
                  <Flex alignItems="center" color="primary">
                    <Box mr={2} fontSize={4}>
                      <Fontello icon="phone" />
                    </Box>
                    <Text fontWeight="bold">{footerSettings.phoneFooter}</Text>
                  </Flex>
                </a>
              </Box>

              <Flex mt={6}>
                <Box fontSize={1} ml={5}>
                  <a href={menusSettings.socials.linkedin} target="_blank">
                    <Box fontSize={6}>
                      <Fontello icon="linkedin" />
                    </Box>
                  </a>
                </Box>
                <Box fontSize={1} ml={5}>
                  <a href={menusSettings.socials.facebook} target="_blank">
                    <Box fontSize={6}>
                      <Fontello icon="facebook" />
                    </Box>
                  </a>
                </Box>
              </Flex>
            </Drawer>
          </Flex>
        </Container>
      </Box>
    </Sticky>
  );
};

NavbarRaw.propTypes = {
  logoAlt: PropTypes.string,
  menusSettings: PropTypes.shape({
    logo: PropTypes.any,
    logo2: PropTypes.any,
    mainmenu: PropTypes.any,
    activeTopMenu: PropTypes.bool
  }),
  footerSettings: PropTypes.shape({
    phoneFooter: PropTypes.any
  }),
  theme: PropTypes.shape({
    space: PropTypes.any
  })
};

const Navbar = ({ theme }) => {
  const { allMarkdownRemark, site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(filter: { frontmatter: { title: { in: ["menus", "footer"] } } }) {
          nodes {
            frontmatter {
              title
              activeTopMenu
              logo2 {
                publicURL
                childImageSharp {
                  fluid(maxHeight: 50, quality: 75) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              logo {
                publicURL
                childImageSharp {
                  fluid(maxHeight: 50, quality: 75) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              mainmenu {
                external
                inNavBar
                inSideBar
                offset
                path
                title
              }
              phoneFooter
              socials {
                linkedin
                facebook
              }
            }
          }
        }
      }
    `
  );

  const menusSettingsFrontmatter =
    allMarkdownRemark.nodes.find(element => element.frontmatter.title === "menus") || {};
  const menusSettings = menusSettingsFrontmatter.frontmatter
    ? menusSettingsFrontmatter.frontmatter
    : {};
  const footerSettingsFrontmatter =
    allMarkdownRemark.nodes.find(element => element.frontmatter.title === "footer") || {};
  const footerSettings = footerSettingsFrontmatter.frontmatter
    ? footerSettingsFrontmatter.frontmatter
    : {};
  return (
    <NavbarRaw
      theme={theme}
      menusSettings={menusSettings}
      footerSettings={footerSettings}
      logoAlt={site.siteMetadata.title}
    />
  );
};

Navbar.propTypes = {
  theme: PropTypes.any
};

export { NavbarRaw };
export default withTheme(Navbar);
