import PropTypes from "prop-types";
import React from "react";
import ReactMarkdown from "react-markdown";
import { Box } from "rebass/styled-components";
const Markdown = ({ children, ...props }) => {
  if (children && typeof children.valueOf() === "string") {
    children = children || "";
    var re = /\\\n/gi;
    children = children.replace(re, "  \n");
    return (
      <Box {...props}>
        <ReactMarkdown source={children} />
      </Box>
    );
  }
  return <></>;
};

Markdown.propTypes = {
  children: PropTypes.string
};
export default Markdown;
