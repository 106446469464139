import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import PropTypes from "prop-types";
import HeaderPage from "../components/UI/HeaderPage";
import { Box } from "rebass/styled-components";

const Page = ({
  children,
  title,
  description,
  thumbnail,
  id,
  breadcrumb,
  customCrumbLabel,
  hideBreadCrumb
}) => {
  thumbnail = thumbnail || false;
  hideBreadCrumb = hideBreadCrumb || false;
  return (
    <Layout>
      <SEO title={title} description={description} thumbnail={thumbnail} />
      <Box id="page--content">{children}</Box>
    </Layout>
  );
};
Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  thumbnail: PropTypes.string,
  id: PropTypes.string,
  breadcrumb: PropTypes.any
};
export default Page;
