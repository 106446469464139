import colors from "./colors";
import icons from "./icons";
const variants = {
  icons: icons,
  avatar: {
    width: "avatar",
    height: "avatar",
    borderRadius: "circle"
  },
  badges: {
    display: "inline-block",
    px: 2,
    py: 1,
    borderRadius: 9999,
    fontSize: 0
  },
  badge: {
    primary: {
      variant: "variants.badges",
      color: "white",
      bg: "primary"
    }
  },
  link: {
    color: "inherit",
    textDecoration: "none",
    fontWeight: "inherit"
  },
  linktext: {
    color: "inherit",
    textDecoration: "inherit"
  },
  darkBg: {
    backgroundColor: "primary",
    color: "background"
  },
  bgImg: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  squareBox: {
    position: "relative",
    "&:after": {
      content: "''",
      display: "block",
      paddingBottom: "100%"
    }
  },
  nav: {
    fontSize: 1,
    fontWeight: "bold",
    display: "inline-block",
    p: 2,
    color: "inherit",
    textDecoration: "none",
    ":hover,:focus,.active": {
      color: "primary"
    }
  },
  cookiesBanner: {
    position: "fixed",
    bottom: "0",
    left: 0,
    right: 0,
    zIndex: 99,
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.4)"
  },
  formItemError: {
    "input,textarea,select": {
      borderColor: colors.danger
    }
  },
  navbar: {
    width: ["100%"],
    transition: "all 300ms ease",
    backgroundColor: "transparent",
    color: "#FFF",
    position: "absolute",
    zIndex: 10,
    "&.sticky": {
      backgroundColor: "background",
      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.4)",
      color: "text",
      borderColor: "primary",
      borderWidth: "0 0 3px 0",
      borderStyle: "solid",
      a: {
        color: "text"
      },
      ".contact-header": {
        bg: "background",
        "&:after": {
          bg: "background"
        }
      }
    }
  },
  menuItem: {
    "a": {
      position: "relative",
      transition: "all 300ms ease",
      "&:after": {
        content: "''",
        display: "block",
        width: "0",
        height: "3px",
        bg: "primary",
        position: "absolute",
        left: "50%",
        bottom: "-10px",
        transform: "translateX(-50%)",
        borderRadius: "3px",
        transition: "all 300ms ease",
      },
      "&:hover": {
        color: "primary"
      },
      "&.active": {
        "&:after": {
          width: "40px",
        }
      }
    }
  },
  menuItemOffcanvas: {
    "a": {
      "button > div": {
        display: "flex",
        alignItems: "center",
        transition: "all 300ms ease",
        position: "relative",
        "&:before": {
          content: "''",
          display: "block",
          width: "0",
          height: "3px",
          bg: "primary",
          borderRadius: "3px",
          transition: "all 300ms ease",
          position: "absolute",
          left: "0",
          bottom: "-10px",
        },
      },
      "&:hover": {
        color: "primary"
      },
      "&.active": {
        "button > div:before": {
          width: "40px",
        }
      }
    }
  },
  container: {
    width: "100%",
    maxWidth: ["100%", "100%", "960px", "1200px"],
    m: "0 auto",
    flexbox: true,
    px: [3,3,6]
  },
  containerFluid: {
    width: "100%",
    m: "0 auto",
    flexbox: true,
    px: [3,3,6]
  },
  grid: {
    flexWrap: "wrap",
    mx: [-3,-3,-3]
  },
  gridItem: {
    px: [3,3,3]
  },
  gridLarge: {
    flexWrap: "wrap",
    mx: [-3,-3,-7]
  },
  gridLargeItem: {
    px: [3,3,7]
  },
  section: {
    width: "100%",
    pt: [10, 10, 11],
    pb: [10, 10, 11]
  },
  card: {
    boxShadow: "card",
    overflow: "hidden",
    p:6,
  },
  horizontalCard: { variant: "variants.card" },
  verticalCard: { variant: "variants.card" },
};

export default variants;
