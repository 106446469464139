import React from "react";

const Fontello = props => {
  if (!props.icon || typeof props.icon !== "string") return null;
  const { icon, className, ...restOfProps } = props;
  let iconValue = icon.match(/^fontello-/) ? icon : `icon-${icon}`;
  const cName = className ? `${className} ` : "";
  return (
    <i
      {...restOfProps}
      className={`${cName}${iconValue}`}
    ></i>
  );
};
export default Fontello;
