import colors from "./colors";
import buttons from "./buttons";
import variants from "./variants";
import forms from "./forms";
import { theme as themeSettings } from "../cms/data/settings";

const theme = {
  colors: colors,
  forms: forms,
  fonts: {
    body: themeSettings.font.fontname + ", sans-serif",
    heading: themeSettings.fontheads.fontname + ", sans-serif",
    monospace: "Menlo, monospace"
  },
  fontSizes: [12, 14, 16, 18, 20, 22, 24, 28, 32, 40, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 700
  },
  lineHeights: {
    body: "normal",
    heading: "normal"
  },
  space: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 100, 128, 256, 512],
  sizes: {
    avatar: 48
  },
  radii: {
    square: 0,
    default: 4,
    big: 8,
    circle: 99999
  },
  shadows: {
    card: "0 0 20px rgba(0, 0, 0, .11)"
  },
  text: {
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
    },
    h1: {
      mb: 0,
      fontSize: [6,10],
      fontWeight: "bold",
    },
    h2: {
      mb: 6,
      fontSize: 9,
      "&:after": {
        content: "''",
        height: "5px",
        borderRadius: "3px",
        width: "90px",
        bg: "primary",
        display: "block",
        mt: 2,
      }
    },
    h3: {
      fontSize: 8,
      mb: 4,
    },
    h4: {
      fontSize: 5,
      mb: 3,
      fontWeight: "bold",
      textTransform: "uppercase",
      pt:1
    },
    h5: {
      fontSize: 3,
      mb: 2,
      fontWeight: "bold",
      textTransform: "uppercase",
      pt:1
    },
    cardTitle: {
      fontSize: 4,
      fontWeight: "bold",
      textAlign: "center"
    },
    display: {
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: [5, 6, 7]
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.1em",
      fontSize: "inherit"
    }
  },
  variants: variants,
  buttons: buttons,
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body"
    }
  },
  breakpoints: Object.values(themeSettings.breakpoints),
  gutter: 30
};
export default theme;
