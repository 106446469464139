import PropTypes from "prop-types";
import React from "react";
import { Box } from "rebass/styled-components";
import IconWrapper from "./IconWrapper";

const Icofont = props => {
  if (!props.icon || typeof props.icon !== "string") return null;
  const { icon, size, rotate, flip, className, spin, ...restOfProps } = props;
  // Look for prefix in icon. Don't prefix it if it's already prefixed
  let iconValue = icon.match(/^icofont-/) ? icon : `icofont-${icon}`;
  // Manage Rotate Value
  const totalRotate = rotate ? ` icofont-rotate-${rotate}` : "";
  // Manage Size value
  const totalSize = size ? ` icofont-${size}x` : "";
  // Manage Flip
  let flipAmount = "";
  if (flip) {
    let flips = flip.split(" ");
    let totalFlip = "";
    if (flips.indexOf("h") !== -1 || flip.indexOf("horizontal") !== -1) {
      totalFlip += " icofont-flip-horizontal";
    }
    if (flips.indexOf("v") !== -1 || flips.indexOf("vertical") !== -1) {
      totalFlip += " icofont-flip-vertical";
    }
    if (totalFlip === "") {
      console.warn("Flip value Invalid for IcoFont");
    }
    flipAmount = totalFlip;
  }
  // Handle ClassName
  const cName = className ? `${className} ` : "";
  // Manage Spin value
  const totalSpin =
    spin && ["true", "1", "yes"].includes(spin.toString().toLocaleLowerCase())
      ? " icofont-spin"
      : "";
  // Render
  return (
    <IconWrapper>
      <Box
        as="i"
        variant={"icons." + iconValue}
        className={`${cName}${totalRotate}${totalSize}${flipAmount}${totalSpin}`}
        sx={{
          fontFamily: "'IcoFont' !important",
          speak: "none",
          fontStyle: "normal",
          fontWeight: "normal",
          fontVariant: "normal",
          textTransform: "none",
          whiteSpace: "nowrap",
          wordWrap: "normal",
          direction: "ltr",
          lineHeight: 1,
          "-webkit-font-feature-settings:": "liga",
          "-webkit-font-smoothing": "antialiased"
        }}
        {...restOfProps}
      />
    </IconWrapper>
  );
};

Icofont.propTypes = {
  className: PropTypes.any,
  flip: PropTypes.shape({
    indexOf: PropTypes.func,
    split: PropTypes.func
  }),
  icon: PropTypes.string,
  rotate: PropTypes.any,
  size: PropTypes.any,
  spin: PropTypes.shape({
    toString: PropTypes.func
  })
};
export default Icofont;
