import styled from "styled-components";

const IconWrapper = styled.span`
  @font-face {
    font-family: "IcoFont";
    font-weight: normal;
    font-style: "Regular";
    src: url("/icofont/fonts/icofont.woff2") format("woff2"),
      url("/icofont/fonts/icofont.woff") format("woff");
    font-display: swap;
  }

  .icofont-xs {
    font-size: 0.5em;
  }

  .icofont-sm {
    font-size: 0.75em;
  }

  .icofont-md {
    font-size: 1.25em;
  }

  .icofont-lg {
    font-size: 1.5em;
  }

  .icofont-1x {
    font-size: 1em;
  }

  .icofont-2x {
    font-size: 2em;
  }

  .icofont-3x {
    font-size: 3em;
  }

  .icofont-4x {
    font-size: 4em;
  }

  .icofont-5x {
    font-size: 5em;
  }

  .icofont-6x {
    font-size: 6em;
  }

  .icofont-7x {
    font-size: 7em;
  }

  .icofont-8x {
    font-size: 8em;
  }

  .icofont-9x {
    font-size: 9em;
  }

  .icofont-10x {
    font-size: 10em;
  }

  .icofont-fw {
    text-align: center;
    width: 1.25em;
  }

  .icofont-ul {
    list-style-type: none;
    padding-left: 0;
    margin-left: 0;
  }

  .icofont-ul > li {
    position: relative;
    line-height: 2em;
  }

  .icofont-ul > li .icofont {
    display: inline-block;
    vertical-align: middle;
  }

  .icofont-border {
    border: solid 0.08em #f1f1f1;
    border-radius: 0.1em;
    padding: 0.2em 0.25em 0.15em;
  }

  .icofont-pull-left {
    float: left;
  }

  .icofont-pull-right {
    float: right;
  }

  .icofont.icofont-pull-left {
    margin-right: 0.3em;
  }

  .icofont.icofont-pull-right {
    margin-left: 0.3em;
  }

  .icofont-spin {
    -webkit-animation: icofont-spin 2s infinite linear;
    animation: icofont-spin 2s infinite linear;
    display: inline-block;
  }

  .icofont-pulse {
    -webkit-animation: icofont-spin 1s infinite steps(8);
    animation: icofont-spin 1s infinite steps(8);
    display: inline-block;
  }

  @-webkit-keyframes icofont-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes icofont-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .icofont-rotate-90 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .icofont-rotate-180 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .icofont-rotate-270 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }

  .icofont-flip-horizontal {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }

  .icofont-flip-vertical {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
    -webkit-transform: scale(1, -1);
    transform: scale(1, -1);
  }

  .icofont-flip-horizontal.icofont-flip-vertical {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
    -webkit-transform: scale(-1, -1);
    transform: scale(-1, -1);
  }

  :root .icofont-rotate-90,
  :root .icofont-rotate-180,
  :root .icofont-rotate-270,
  :root .icofont-flip-horizontal,
  :root .icofont-flip-vertical {
    -webkit-filter: none;
    filter: none;
    display: inline-block;
  }

  .icofont-inverse {
    color: #fff;
  }

  .sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .sr-only-focusable:active,
  .sr-only-focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
`;

export default IconWrapper;
