import PropTypes from "prop-types";
import React, { useState } from "react";
import { withTheme } from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Section from "./UI/Section";
import Container from "./UI/Container";
import CustomImage from "./UI/CustomImage";
import Link from "./UI/Link";
import Fontello from "./UI/Fontello";
import Markdown from "./UI/Markdown";
import { Box, Flex, Text, Button } from "rebass/styled-components";

const FooterRaw = ({ theme, menusSettings, footerSettings, logoAlt }) => {
  logoAlt = logoAlt || "Logo";
  const [stiked, setStiked] = useState(false);
  const [open, setOpen] = useState(false);
  const toggleHandler = () => {
    setOpen(!open);
  };
  var telLink = "tel:+687";
  var mailTo = "mailto:";


  if (!menusSettings.activeFooter) {
    return <></>;
  }
  return (
    <Section bg="footer" color="#fff" py={[0,0,0,0]}>      
      <Container py={6}>
        <Flex
        justifyContent={["center","flex-start"]}
        flexDirection={["column","column","row"]}
        flexWrap="wrap">

          <Box
          mr={[0,0,13]}
          py={6}
          width={["100%","100%","100%","auto"]}>
            <CustomImage img={footerSettings.logoFooter} height="auto" width="170px" mx={[0,0,0]} />
            <Box
            mt={10}
            sx={{
              "a": {
                display: "block",
                "&:hover": {
                  color: "primary",
                }
              }
            }}>
              <a href={menusSettings.socials.linkedin} target="_blank">
                <Flex alignItems="center"><Box fontSize={6}><Fontello icon="linkedin" /></Box><Box mx={2}>Linked in</Box></Flex>
              </a>
            </Box>
            <Box
            mt={6}
            sx={{
              "a": {
                display: "block",
                "&:hover": {
                  color: "primary",
                }
              }
            }}>
              <a href={menusSettings.socials.facebook} target="_blank">
                <Flex alignItems="center"><Box fontSize={6}><Fontello icon="facebook" /></Box><Box mx={2}>Facebook</Box></Flex>
              </a>
            </Box>
          </Box>

          <Box
          py={6}
          mr={[0,0,13]}
          sx={{
            "p:last-child":{m:0},
            "a": {
              "&:hover": {
                color: "primary"
              }
            }
          }}>
            <Text variant="h3" textAlign={["left","left","left"]}>Contact</Text>
            
            <Flex
            justifyContent={["flex-start","flex-start","flex-start"]}>
              <Box>
                <Flex mb={4}>
                  <Box mr={3} color="primary" fontSize={6}><Fontello icon="location" /></Box>
                  <Markdown>{footerSettings.addressFooter}</Markdown>
                </Flex>
                <a href={mailTo + footerSettings.mailFooter}>
                  <Flex alignItems="center" mb={4}>
                    <Box mr={3} color="primary" fontSize={6}><Fontello icon="mail" /></Box>
                    <Markdown>{footerSettings.mailFooter}</Markdown>
                  </Flex>
                </a>
                <a href={(telLink = telLink + footerSettings.phoneFooter.replace(/[- )(]/g, ""))}>
                  <Flex alignItems="center" mb={4}>
                    <Box mr={3} color="primary" fontSize={6}><Fontello icon="phone" /></Box>
                    <Markdown>{footerSettings.phoneFooter}</Markdown>
                  </Flex>
                </a>
                <Flex alignItems="center" mb={4}>
                  <Box mr={3} color="primary" fontSize={6}><Fontello icon="stopwatch" /></Box>
                  <Markdown>{footerSettings.opentimeFooter}</Markdown>
                </Flex>
              </Box>
            </Flex>

          </Box>

          <Box
          py={6}
          sx={{
            "a": {
              "&:hover": {
                color: "primary"
              }
            }
          }}>
            <Text variant="h3" textAlign={["left","left","left"]}>Plan du site</Text>
            {menusSettings.mainmenu.map((item, i) => (
              <Box mt={3} textAlign={["left","left","left"]} key={"menu-" + i}>
                <Link
                  activeClassName="active"
                  href={item.path}
                  external={item.external}
                >
                  <Button variant="ninja">
                    <Text>{item.title}</Text>
                  </Button>
                </Link>
              </Box>
            ))}
          </Box>

        </Flex>
      </Container>        
        
      <Box bg="black">
        <Container>
          <Flex
          justifyContent={["center","center","space-between"]}
          flexDirection={["column","column","row"]}
          alignItems="center"
          py={3}
          sx={{
            "a": {
              "&:hover": {
                color: "primary"
              }
            }
          }}>
            <Flex
            justifyContent={["center","center","flex-start"]}
            flexDirection={["column","row"]}
            alignItems="center">
              {menusSettings.footermenu.map((item, i) => (
                <Box ml={[0,3,0]} mr={[0,3,6]} mb={[3,0,0]} key={"menu-" + i}>
                  <Link
                    activeClassName="active"
                    href={item.path}
                    external={item.external}
                  >
                    <Button variant="ninja">
                      <Text fontSize={1}>{item.title}</Text>
                    </Button>
                  </Link>
                </Box>
              ))}
            </Flex>
            <Box fontSize={1} mt={[6,6,0]}>
              <>
                Réalisation :{" "}
                <a href="https://la-fabrik.nc">
                  <strong>La Fabrik</strong>
                </a>
              </>
            </Box>
          </Flex>
        </Container>
      </Box>
    </Section>
  );
};

FooterRaw.propTypes = {
  logoAlt: PropTypes.string,
  footerSettings: PropTypes.shape({
    addressFooter: PropTypes.any,
    logoFooter: PropTypes.any,
    phoneFooter: PropTypes.any,
    opentimeFooter: PropTypes.any
  }),
  menusSettings: PropTypes.shape({
    footermenu: PropTypes.array,
    footermenuen: PropTypes.array,
    activeFooter: PropTypes.bool
  }),
  theme: PropTypes.shape({
    space: PropTypes.any
  })
};


const Footer = ({ theme }) => {
  const { allMarkdownRemark, site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(filter: { frontmatter: { title: { in: ["menus", "footer"] } } }) {
          nodes {
            frontmatter {
              title
              activeFooter
              mainmenu {
                offset
                path
                title
                external
              }
              footermenu {
                offset
                path
                title
                external
              }
              logoFooter {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 300, quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              addressFooter
              phoneFooter
              mailFooter
              opentimeFooter
              socials {
                linkedin
                facebook
              }
            }
          }
        }
      }
    `
  );

  const menusSettingsFrontmatter =
    allMarkdownRemark.nodes.find(element => element.frontmatter.title === "menus") || {};
  const menusSettings = menusSettingsFrontmatter.frontmatter
    ? menusSettingsFrontmatter.frontmatter
    : {};
  const footerSettingsFrontmatter =
    allMarkdownRemark.nodes.find(element => element.frontmatter.title === "footer") || {};
  const footerSettings = footerSettingsFrontmatter.frontmatter
    ? footerSettingsFrontmatter.frontmatter
    : {};
  return (
    <FooterRaw
      theme={theme}
      menusSettings={menusSettings}
      footerSettings={footerSettings}
      logoAlt={site.siteMetadata.title}
    />
  );
};

Footer.propTypes = {
  theme: PropTypes.any
};

export { FooterRaw };
export default withTheme(Footer);
