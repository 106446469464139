import styled, { css, createGlobalStyle } from "styled-components";
import "./reset.css";
import { width, height, color, space, boxShadow, borderRadius } from "styled-system";
import { themeGet } from "@styled-system/theme-get";
import { normalize } from "polished";

const fontelloCss = css`
  ${import("../components/UI/Fontello/fontello/css/fontello.css")}
`;

const GlobalStyle = createGlobalStyle`
${normalize()}
${fontelloCss}
*{
  font-family: ${themeGet("fonts.body")};
}
h1,h2,h3,h4,h5,h6{
  font-family: ${themeGet("fonts.heading")};
}
.drawer-content{
  background-color:${themeGet("colors.background")};
  color: ${themeGet("colors.text")};
}
html {
  line-height: 1.4;
}
  body {
    background-color:${themeGet("colors.background")};
    font-family: ${themeGet("fonts.body")};
    color: ${themeGet("colors.text")};
    font-size:${themeGet("fontSizes.2")}px;
    margin: 0;
    #page--content {
      div {
        p {
          &:first-child {
            margin-top: 0;
          }
        }
        ul {
          list-style: none;
          padding-left: 0;
          li {
            position: relative;
            padding-bottom: ${themeGet("space.2")}px;
            padding-left: ${themeGet("space.4")}px;
            &:before {
              content: "";
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background: ${themeGet("colors.primary")};
              margin: 7px 15px 0 0;
              position: absolute;
              left: 0;
              top: 0;
            }
            &:last-child {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
  *{
    font-family: ${themeGet("fonts.body")};
    &:focus {
    outline: none;
    outline-color: transparent;
    outline-style: auto;
    outline-width: 0px;
}
a{
  color:inherit;
  text-decoration:inherit;
  font-size:inherit;
  font-weight:inherit;
  word-break: break-word;
  line-height: normal;
}
  }
  img{
    max-width:100%;
  }
  .react-parallax-bgimage{
    max-width:unset;
  }
  .mapboxgl-ctrl-attrib{
    display:none !important;
  }
[class^="icon-"]:before,
[class*=" icon-"]:before {
  margin :0;
}
.gatsby-image-wrapper img {
  height: auto !important;
}
`;

const SiteWrapper = styled.div`
  /* Style system supported prop */
  

  ${width}
  ${height}
  ${color}
  ${space}
  ${boxShadow}
  ${borderRadius}
`;

GlobalStyle.displayName = "GlobalStyle";
SiteWrapper.displayName = "SiteWrapper";

export { GlobalStyle, SiteWrapper };
