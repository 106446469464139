import PropTypes from "prop-types";
import React from "react";
import { Flex, Box, Heading, Text } from "rebass/styled-components";
import Container from "./Container";
import Markdown from "./Markdown";

const HeaderPage = ({ title, content }) => {
  return (
    <Flex
      sx={{
        position: "relative",
        "&:before": {
          content: "''",
          display: "block",
          position: "absolute",
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          width: "102vw",
          height: "100%",
          bg: "gray",
          zIndex: 1
        }
      }}
    >
      <Container
        sx={{
          position: "relative",
          zIndex: 2,
          mt: "auto"
        }}
      >
        <Box
          pt={["65px", "80px", "80px", "140px"]}
          pb={[6, 6, 11]}
          sx={{
            position: "relative",
            "&:before": {
              content: "''",
              display: "block",
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "20%",
              height: "5px",
              bg: "primary",
              zIndex: 1,
              borderRadius: "3px"
            }
          }}
        >
          <Heading as="h1" variant="h1" color="white">
            {title}
          </Heading>
          {content && (
            <Markdown color="white" pt={[2, 3]} sx={{ p: { mb: 0 } }}>
              {content}
            </Markdown>
          )}
        </Box>
      </Container>
    </Flex>
  );
};

HeaderPage.propTypes = {
  title: PropTypes.any
};

export default HeaderPage;
